import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from './../components/Seo'
import SectionProvider from '../components/Layout/SectionProvider'

const Page = ({ 
    data: {
        contentfulPage: {
            name,
            sections
        }
    }
}) => {
    return (
        <>
            <Seo
                title={name}
            />
            {sections && <SectionProvider sections={sections} />}
        </>
    )
}

export default Page

export const query = graphql`
	query($id: String!) {
		contentfulPage(id: {eq: $id}) {
            name
            sections {
                __typename ... on Node {
                    ... on ContentfulSectionBanner {
                        ...sectionBanner
                    }
                    ... on ContentfulSectionHeroBanner {
                        ...sectionHeroBanner
                    }
                    ... on ContentfulSectionRow {
                        ...sectionRow
                    }
                }
            }
        }
	}
`

// seoImage {
//     fixed(width: 1200, height: 630, toFormat: JPG, cropFocus: CENTER) {
//         src
//     }
// }